import {styled, Typography} from '@mui/material';
import {FC, ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';
import {LandingBackground} from './LandingBackground';

const Div = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.error.light,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 40,
    margin: 8,
    padding: 20,
}));

type Props = {
    readonly children: ReactNode;
};

/**
 * Wrapper komponenta pro přihlášení a reset hesla
 */
export const LandingLayout: FC<Props> = ({children}) => {
    return (
        <LandingBackground>
            <Div>
                <Typography
                    sx={{
                        color: 'common.white',
                        textAlign: 'center',
                    }}
                    variant={'h3'}
                >
                    <FormattedMessage id="portal" />
                </Typography>
                {children}
            </Div>
        </LandingBackground>
    );
};
